<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-row>
          <v-col cols="12" md="4">
            <p class="title main-color" style="text-align: left">
              {{ $i18n.t("Exams") }}
            </p>
          </v-col>
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="4">
            <v-row class="align-items-center">
              <v-col cols="6" sm="6" class="academic">
                <label for="academicPeriods" class="label">{{
                  $t("Academic Year")
                }}</label>
              </v-col>
              <v-col cols="6" sm="6">
                <v-select
                  id="academicPeriods"
                  v-model="selectedAcademicPeriodId"
                  :items="academicPeriods"
                  item-text="en.name"
                  item-value="id"
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Body -->
        <v-row>
          <v-col md="6" class="" v-for="grade in grades" :key="grade">
            <v-card class="card-container">
              <v-card-text style="padding: 15px 20px">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row>
                        <v-col md="8" cols="12">
                          <h3 class="title-panel">
                            {{ grade.grade_name }}
                          </h3>
                        </v-col>
                        <v-col md="3" cols="12"> </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr style="background: #fafafa">
                              <th
                                class="text-left bold"
                                style="font-size: 14px !important"
                              >
                                {{ $t("Subject") }}
                              </th>
                              <th
                                class="text-left bold"
                                style="font-size: 14px !important"
                              >
                                {{ $t("Subject Type") }}
                              </th>

                              <th
                                class="text-left bold"
                                style="font-size: 14px !important"
                              >
                                {{ $t("Exams") }}
                              </th>

                              <!-- <th class="text-left"></th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index2) in grade.items"
                              :key="index2"
                            >
                              <td style="font-size: small">
                                {{ item.subject }}
                              </td>
                              <td style="font-size: small">
                                {{ item.subject_type }}
                              </td>
                              <td>
                                <v-btn
                                  v-if="item"
                                  class="main-color"
                                  @click="
                                    manage(
                                      item.subject_id,
                                      grade.academic_grade_id
                                    )
                                  "
                                  :title="$t('Manage')"
                                  style="
                                    background-color: transparent;
                                    box-shadow: none !important;
                                    text-decoration: underline;
                                    font-size: small;
                                  "
                                >
                                  {{ $t("Manage") }}
                                </v-btn>
                              </td>
                              <!-- <td>
                                <v-icon
                                  @click="ShowActivity(index2)"
                                  :title="$t('Edit')"
                                  class="main-color"
                                  >edit</v-icon
                                >
                              </td> -->
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Exams",
  // components: { AddEditAccountComponent },
  data() {
    return {
      selectedAcademicPeriodId: "",
      academicPeriods: [],
      grades: [],
    };
  },
  watch: {
    selectedAcademicPeriodId: {
      handler() {
        this.getAllData();
      },
      deep: true,
    },
  },
  methods: {
    getAcademicPeriods() {
      axios
        .get(this.getApiUrl + "/school/showAcademicPeriod?list=yes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.academicPeriods = response.data.data;
          this.selectedAcademicPeriodId = this.academicPeriods.find(
            (object) => object.is_active == 1
          ).id;

          this.activeAcademicPeriod = this.selectedAcademicPeriodId;
        });
    },
    getAllData() {
      axios
        .get(
          this.getApiUrl +
            "/performance/getSubjectsPerGrades/" +
            this.selectedAcademicPeriodId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.grades = [];
          this.grades = response.data.data;
        });
    },
    manage(subject_id, academic_grade_id) {
      // console.log(subject_id, grade_id);
      this.$router.push(
        {
          path: "/exams/manage/" + academic_grade_id + "/" + subject_id,
        },
        () => {}
      );
    },
  },
  mounted() {
    this.getAcademicPeriods();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// end search input
.title-page {
  margin-top: 15px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.card-container {
  max-width: 80%;
  border-radius: 10px;
  position: relative;
  margin-bottom: 50px;
}
.card-container .actions {
  position: absolute;
  top: -39px;
  right: 22px;
}
.card-container .actions i,
.card-container .actions span {
  cursor: pointer;
}
.card-container .actions span {
  margin-left: 5px;
}

.title-qualifire .divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
.q-box-title {
  font-size: 29px;
  position: relative;
}

.q-box-title h2 {
  font-size: 25px;
  padding: 0;
  margin: 0;
}

.title-page {
  margin-top: 15px;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #eef3ff;
  margin-bottom: 33px;
}
.v-expansion-panel-content {
  background-color: #f7f7f7;
}
.title-panel {
  color: rgb(114, 151, 255);
  margin: 0;
  margin-top: 15px;
  padding: 0;
}
.details {
  margin-top: 6%;
  overflow: hidden;
}
.details span {
  color: #8d8a8a;
  font-size: 17px;
  font-weight: 700;
}
.details span.passing {
  float: right;
}
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
